import React from 'react';
import './App.css';
import ContentLayout from './components/content-layout';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

const App = () => {
  return (  
      <>
   <Router>
    <Routes>
      <Route path="*"element={<ContentLayout/>} />
    </Routes>
   </Router>
   </>

   
  ) 
}
export default App;
