import React, { createContext, useState } from "react";
import { Route, Routes } from "react-router-dom";
import Nav from "../user-control/nav";
import Footer from "../user-control/footer";
import Loadable from 'react-loadable';

const Loading = () => null;

const Landing = Loadable({
    loader: () => import("../apps-library/landing/Index"),
    loading: Loading
});

const Profile = Loadable({
    loader: () => import("../apps-library/about/profile/"),
    loading: Loading
});

const Lokasi = Loadable({
    loader: () => import("../apps-library/about/lokasi/"),
    loading: Loading
});

const Karir = Loadable({
    loader: () => import("../apps-library/about/karir/"),
    loading: Loading
});

const KarirList = Loadable({
    loader: () => import("../apps-library/about/karir_list/"),
    loading: Loading
});

const KarirDetail = Loadable({
    loader: () => import("../apps-library/about/karir_detail/"),
    loading: Loading
});

const CekOngkir = Loadable({
    loader: () => import("../apps-library/cek_tarif/"),
    loading: Loading
});

const Lacak = Loadable({
    loader: () => import("../apps-library/lacak/"),
    loading: Loading
});

const ContactUs = Loadable({
    loader: () => import("../apps-library/contact_us/"),
    loading: Loading
});

const FAQ = Loadable({
    loader: () => import("../apps-library/faq/list/"),
    loading: Loading
});

const FAQDetail = Loadable({
    loader: () => import("../apps-library/faq/detail/"),
    loading: Loading
});

const News = Loadable({
    loader: () => import("../apps-library/news/list/"),
    loading: Loading
});

const NewsDetail = Loadable({
    loader: () => import("../apps-library/news/detail/"),
    loading: Loading
});


export const NavContext = createContext(null);

const ContentLayout = () => {
    const [navData, setNavData] = useState({ 
        hide: false, 
        th: false, 
        transparent: false, 
        blur: false,
        separator: false,
        textColor: 'text-black',
    });

    const navSetData = {
        setNavData: setNavData
    }

return (
    <div className={"leading-normal tracking-normal text-white gradient font-inter"}>
        <NavContext.Provider value={{ navData, navSetData }}>
            <div className={(navData.hide ? 'hidden' : '')}>
                <Nav />
            </div>
            <Routes>
                <Route path="/" element={<Landing />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/cek_tarif" element={<CekOngkir/>} />
                <Route path="/lacak" element={<Lacak/>} />
                <Route path="/news" element={<News/>} />
                <Route path="/contact_us" element={<ContactUs/>} />
                <Route path="/lokasi" element={<Lokasi/>} />
                <Route path="/karir" element={<Karir/>} />
                <Route path="/karir_list/:tags" element={<KarirList/>} />
                <Route path="/karir_detail/:uid" element={<KarirDetail/>} />
                <Route path="/news_detail/:uid" element={<NewsDetail/>} />
                <Route path="/faq" element={<FAQ/>} />
                <Route path="/faq_detail" element={<FAQDetail/>} />
                {/* <Route path="/about" element={<About />} />
                <Route path="/cek-tarif" element={<CekTarif />} />
                <Route path="/lacak" element={<Lacak />} />
                <Route path="/gerai" element={<Gerai />} />
                <Route path="/mitra" element={<Mitra />} />                    
                <Route path="/terms-condition" element={<TermsCondition />} />                    
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />                    
                <Route path="/faq" element={<FAQ />} />         */}
                {/* <Route path="/trial" element={<Trial />} />                                 */}
            </Routes>
            <Footer />  
        </NavContext.Provider>
    </div>
    
)
            }
export default ContentLayout;