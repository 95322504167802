import '../../../index.css';
import '../../../navbar.css';
import logoputih from '../../../../src/assets/img/logo putih-01_fix.png';
import supportlogo from '../../../../src/assets/img/contact_us-fix.png';
import logowarna from '../../../../src/assets/img/logo_warna.png';
import React, { useState } from 'react';
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>;

const Nav = () => {
    const [isResponsive, setIsResponsive] = useState(false);

    const handleNavToggle = () => {
        setIsResponsive(prevState => !prevState);
    };

    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <>
            <div className={`topnav`} style={{ zIndex: '100' }} id="myTopnav">
                <div className="left-section">
                    <a href="/" className="image-link"><img className='box-content' src={logoputih} alt="" style={{ width: "170px", height: "auto" }} /></a>
                </div>
                <div className="center-section">
                    <a href="/profile">Tentang Kami</a>
                    <a href="/lokasi">Lokasi</a>
                    <a href="/lacak">Lacak</a>
                    <a href="/cek_tarif">Cek Ongkir</a>
                    <a href="/news">Berita</a>
                    <a href="/karir">Karir</a>
                </div>
                <div className="right-section">
                    <a href="https://wa.me/6281294447887">
                        <img className='box-content' src="https://storage.googleapis.com/21e_hayzm5123abc_qweasdzxc/aiRheTjkQwe/web-compro/contact_us3.png" alt="" style={{ width: "190px", height: "auto" }} />
                    </a>
                </div>
            </div>

            {/* responsive mobile */}
           <nav className={`lg:hidden fixed top-0 left-0 right-0 z-50 p-3 ${isOpen ? 'bg-white' : 'bg-blue-900'}`}>
    <div className="max-w-7xl mx-auto flex justify-between items-center">
        {/* Logo or brand */}
        <div className={`flex-shrink-0 ${isOpen ? 'ml-4 md:ml-0' : 'mx-auto'}`}>
            <a href="/" className={`${isOpen ? 'text-black' : 'text-white'}`}>
                <img src={isOpen ? "https://storage.googleapis.com/21e_hayzm5123abc_qweasdzxc/aiRheTjkQwe/web-compro/png_21e_logo.png" : logoputih} alt="21 Express" className="w-50" style={{ width: '150px' }} />
            </a>
        </div>
        {/* Mobile menu button */}
        <div className="lg:hidden">
            <button
                className={`focus:outline-none ${isOpen ? 'text-black' : 'text-white'}`}
                onClick={toggleMenu}
            >
                {/* Hamburger icon */}
                <svg
                    className="h-6 w-6 inline"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                >
                    {isOpen ? (
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M6 18L18 6M6 6l12 12"
                        />
                    ) : (
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M4 6h16M4 12h16M4 18h16"
                        />
                    )}
                </svg>  
            </button>
        </div>
    </div>
    {/* Mobile menu */}
    <div className={`lg:hidden bg-blue-900 mt-2 ${isOpen ? 'opacity-100 visible' : 'opacity-0 invisible'}`}>
        <div className="absolute top-16 left-0 right-0 bg-white shadow-lg">
            <div className="border-t border-gray-300"></div>
            {isOpen && (
                <>
                    <a href="/profile" className="block font-sans mt-8 ml-5 mb-3 font-bold text-black hover:text-gray-700 px-3 py-2">Tentang Kami</a>
                    <a href="/lokasi" className="block font-sans ml-5 mb-3 font-bold text-black hover:text-gray-700 px-3 py-2">Lokasi</a>
                    <a href="/lacak" className="block font-sans ml-5 mb-3 font-bold text-black hover:text-gray-700 px-3 py-2">Lacak</a>
                    <a href="/cek_tarif" className="block font-sans ml-5 mb-3 font-bold text-black hover:text-gray-700 px-3 py-2">Cek Ongkir</a>
                    <a href="/news" className="block font-sans ml-5 mb-3 font-bold text-black hover:text-gray-700 px-3 py-2">Berita</a>
                    <a href="/karir" className="block font-sans ml-5 mb-3 font-bold text-black hover:text-gray
                    -700 px-3 py-2">Karir</a>
                    <a href="https://wa.me/6281294447887" className="block font-sans ml-5 mb-20 font-bold text-black hover:text-gray-700 px-3 py-2">Customer Service</a>
                </>
            )}
        </div>
    </div>
</nav>

        </>
    );
}

const subject = "";
export default Nav;
export { subject };
