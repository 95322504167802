import React from 'react';
import { useNavigate, NavLink } from "react-router-dom";
import '../../../footer.css';
import '../../../index.css';
import logoputih from '../../../../src/assets/img/logoputih.png'
// !Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.


const FT =()=> { 
  return (
<>
<div class="mobile-card-footer"> </div>
<div class="card-footer">
<footer class="footer">
   <div className="footer-section">
      <a href="/"><img className="logo max-w-full md:max-w-none" src={logoputih} alt="" style={{ width: "400px", height: "auto" }} /></a>
      <p>
         21Express hadir dengan jaringan yang sangat luas dan tersebar hampir di seluruh indonesia dengan titik layanan (point of sales) dari Sabang sampai Merauke.
      </p>
      <p style={{ fontSize: '14px' }}>© 2024 21Express. All rights reserved.</p>
  </div>
  <div className="footer-section">
    <h4 style={{marginTop: "0", marginBottom: "10px"}}>Halaman</h4>
    <a href="/profile"><p style={{ fontSize: '14px' }}>Tentang Kami</p></a>
    <a href="/lacak"><p style={{ fontSize: '14px'  }}>Lacak</p></a>
    <a href="/cek_tarif"><p style={{ fontSize: '14px' }}>Cek Ongkir</p></a>
    <a href="/lokasi"><p style={{ fontSize: '14px' }}>Lokasi</p></a>
    <a href="/karir"><p style={{ fontSize: '14px' }}>Karir</p></a>
    <a href="/faq"><p style={{ fontSize: '14px' }}>FAQ</p></a>
  </div>
  <div className="footer-section">
  <h4 style={{marginTop: "0", marginBottom: "10px"}}>Layanan</h4>
    <p style={{ fontSize: '14px'  }}>Reguler</p>
    <p style={{ fontSize: '14px' }}>One Night Service</p>
    <p style={{ fontSize: '14px' }}>Same Day Service</p>
  </div>
  <div className="footer-section" style={{ flexBasis: '15%' }}>
   <h4 style={{marginTop: "0", marginBottom: "10px"}}>Temukan Kami</h4>
   
      <div style={{ display: 'grid', gridTemplateColumns: 'auto 1fr', gap: '8px'}}>
         <div style={{ marginLeft: '40px'}}>
            <svg width="24px" height="24px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={{ marginRight: '10px' }}>
            <path fill="#ffffff" d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z"/></svg>
         </div>
         <div>
            <p style={{ fontSize: '14px', marginTop: '4px', marginLeft: '-40px' }}>customercare@21express.co.id</p>
         </div>
      </div>
            
      <div style={{ display: 'grid', gridTemplateColumns: 'auto 1fr', gap: '8px'}}>
         <div style={{ marginTop: '3px', marginLeft: '40px' }}>
            <svg width="24px" height="24px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
            <path fill="#ffffff" d="M16 64C16 28.7 44.7 0 80 0H304c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H80c-35.3 0-64-28.7-64-64V64zM144 448c0 8.8 7.2 16 16 16h64c8.8 0 16-7.2 16-16s-7.2-16-16-16H160c-8.8 0-16 7.2-16 16zM304 64H80V384H304V64z"/></svg>
         </div>
         <div>
            <p style={{ fontSize: '14px', marginTop: '4px', marginLeft: '-40px'}}>(021) 22442121</p>
         </div>
      </div>
      

      <div style={{ display: 'grid', gridTemplateColumns: 'auto 1fr', gap: '8px'}}>
         <div style={{ marginTop: '7px', marginLeft: '40px'}}>
            <svg width="24px" height="24px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
            <path fill="#ffffff" d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"/></svg>
         </div>
         <div>
            <p style={{ fontSize: '14px', marginTop: '4px', marginLeft: '-40px' }}>Jl. Raya Bekasi KM.19 No. 1-3, RT.4/RW.1, Rw Terate, Kec. Cakung, Kota Jakarta Timur, Daerah Khusus Ibukota Jakarta 13250</p>
         </div>
      </div>
      
      <div style={{marginLeft: '40px'}}>
         <a style={{display: 'inline-block', marginRight: '30px'}} target='_blank' href="https://www.facebook.com/21ExpressID">
            <svg width="25px" height="25px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path fill="#ffffff" d="M512 256C512 114.6 397.4 0 256 0S0 114.6 0 256C0 376 82.7 476.8 194.2 504.5V334.2H141.4V256h52.8V222.3c0-87.1 39.4-127.5 125-127.5c16.2 0 44.2 3.2 55.7 6.4V172c-6-.6-16.5-1-29.6-1c-42 0-58.2 15.9-58.2 57.2V256h83.6l-14.4 78.2H287V510.1C413.8 494.8 512 386.9 512 256h0z"/></svg>
         </a>
         <a style={{display: 'inline-block', marginRight: '30px'}} target='_blank' href="https://www.tiktok.com/@duasatuexpress">
         <svg width="25px" height="25px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
            <path fill="#ffffff" d="M448 209.9a210.1 210.1 0 0 1 -122.8-39.3V349.4A162.6 162.6 0 1 1 185 188.3V278.2a74.6 74.6 0 1 0 52.2 71.2V0l88 0a121.2 121.2 0 0 0 1.9 22.2h0A122.2 122.2 0 0 0 381 102.4a121.4 121.4 0 0 0 67 20.1z"/></svg>
         </a>
         <a style={{display: 'inline-block', marginRight: '30px'}} target='_blank' href="https://twitter.com/21expressid">
            <svg width="25px" height="25px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path fill="#ffffff" d="M459.4 151.7c.3 4.5 .3 9.1 .3 13.6 0 138.7-105.6 298.6-298.6 298.6-59.5 0-114.7-17.2-161.1-47.1 8.4 1 16.6 1.3 25.3 1.3 49.1 0 94.2-16.6 130.3-44.8-46.1-1-84.8-31.2-98.1-72.8 6.5 1 13 1.6 19.8 1.6 9.4 0 18.8-1.3 27.6-3.6-48.1-9.7-84.1-52-84.1-103v-1.3c14 7.8 30.2 12.7 47.4 13.3-28.3-18.8-46.8-51-46.8-87.4 0-19.5 5.2-37.4 14.3-53 51.7 63.7 129.3 105.3 216.4 109.8-1.6-7.8-2.6-15.9-2.6-24 0-57.8 46.8-104.9 104.9-104.9 30.2 0 57.5 12.7 76.7 33.1 23.7-4.5 46.5-13.3 66.6-25.3-7.8 24.4-24.4 44.8-46.1 57.8 21.1-2.3 41.6-8.1 60.4-16.2-14.3 20.8-32.2 39.3-52.6 54.3z"/></svg>
         </a>
         <a style={{display: 'inline-block', marginRight: '30px'}} target='_blank' href="https://www.instagram.com/21express.id/">
            <svg width="25px" height="25px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
            <path fill="#ffffff" d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/></svg>
         </a>
         <a style={{display: 'inline-block'}} target='_blank' href="https://www.youtube.com/@21express8">  
            <svg width="25px" height="25px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
            <path fill="#ffffff" d="M549.7 124.1c-6.3-23.7-24.8-42.3-48.3-48.6C458.8 64 288 64 288 64S117.2 64 74.6 75.5c-23.5 6.3-42 24.9-48.3 48.6-11.4 42.9-11.4 132.3-11.4 132.3s0 89.4 11.4 132.3c6.3 23.7 24.8 41.5 48.3 47.8C117.2 448 288 448 288 448s170.8 0 213.4-11.5c23.5-6.3 42-24.2 48.3-47.8 11.4-42.9 11.4-132.3 11.4-132.3s0-89.4-11.4-132.3zm-317.5 213.5V175.2l142.7 81.2-142.7 81.2z"/></svg>
         </a>
      </div>
   </div>
  </footer>
</div>

<div class="mobile-card-footer"> </div>


</>
  )
}
export default FT;
const subject="";
export {subject};